<template>
    <!-- 学员证件监查 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">系统监管</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员证件监查</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items: flex-start">
                    <div class="searchbox" style="margin-bottom: 20px;">
                        <div class="searchboxItem ci-full">
                            <span title="学员信息" class="itemLabel">学员信息:</span>
                            <el-select
                                v-model="searchForm.userId"
                                placeholder="请选择学员"
                                remote
                                size="small"
                                clearable
                                :remote-method="superUserSelect"
                                @visible-change="clearUserSearchModel">
                                <template>
                                    <div class="select-header">
                                        <span style=" color: #8492a6; float: left; font-size: 13px; width: 100px;">学员姓名</span>
                                        <span style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 140px;">身份证号码</span>
                                        <span style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 100px;">学员电话</span>
                                    </div>
                                    <div class="select-header">
                                        <el-input
                                            v-model="searchInputForm.userName"
                                            v-on:input="superUserSelect"
                                            type="text"
                                            size="small"
                                            placeholder="学员姓名"
                                            clearable
                                            style="width: 100px"
                                        />
                                        <el-input
                                            v-model="searchInputForm.idcard"
                                            v-on:input="superUserSelect"
                                            type="text"
                                            size="small"
                                            placeholder="身份证号码"
                                            clearable
                                            style="margin-left: 50px; width: 140px"
                                        />
                                        <el-input
                                            v-model="searchInputForm.mobile"
                                            v-on:input="superUserSelect"
                                            type="text"
                                            size="small"
                                            placeholder="学员电话"
                                            clearable
                                            style="margin-left: 50px; width: 100px"
                                        />
                                    </div>
                                </template>
                                <el-option v-for="(item, index) in userList" :key="index" :label="item.userName" :value="item.userId">
                                    <span style="width: 100px; font-size: 13px">{{item.userName}}</span>
                                    <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                                    <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="searchboxItem ci-full">
                            <span title="班级信息" class="itemLabel">班级信息:</span>
                            <el-select
                                v-model="searchForm.projectId"
                                placeholder="请选择班级"
                                remote
                                size="small"
                                clearable
                                :remote-method="superProjectSelect"
                                @visible-change="clearProjectSearchModel">
                                <template>
                                    <div class="select-header">
                                        <span style=" color: #8492a6; float: left; font-size: 13px; width: 150px;">班级编号</span>
                                        <span style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 250px;">班级名称</span>
                                    </div>
                                    <div class="select-header">
                                        <el-input
                                            v-model="searchInputForm.projectCode"
                                            v-on:input="superProjectSelect"
                                            type="text"
                                            size="small"
                                            placeholder="班级编号"
                                            clearable
                                            style="width: 150px"
                                        />
                                        <el-input
                                            v-model="searchInputForm.projectName"
                                            v-on:input="superProjectSelect"
                                            type="text"
                                            size="small"
                                            placeholder="请输入班级名称"
                                            clearable
                                            style="margin-left: 50px; width: 250px"
                                        />
                                    </div>
                                </template>
                                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName" :value="item.projectId">
                                    <span style="width: 150px; font-size: 13px">{{item.projectCode}}</span>
                                    <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="searchboxItem ci-full">
                            <span title="课程名称" class="itemLabel">课程名称:</span>
                            <el-input
                                v-model="searchForm.courseName"
                                type="text"
                                size="small"
                                placeholder="请输入课程名称"
                                clearable
                            />
                        </div>
                        <div class="searchboxItem ci-full">
                            <span title="是否上传证件照片" class="itemLabel">是否上传证件照片:</span>
                            <el-select v-model="searchForm.isUpload" placeholder="请选择" size="small" clearable>
                                <el-option
                                    label="是"
                                    :value="true">
                                </el-option>
                                <el-option
                                    label="否"
                                    :value="false">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="searchbox">
                        <div class="searchboxItem ci-full">
                            <span title="创建时间" class="itemLabel">创建时间:</span>
                            <el-date-picker
                                @change="dataPickerChange"
                                v-model="searchInputForm.certificationTime"
                                size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetimerange"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00','23:59:59']">
                            </el-date-picker>
                        </div>
                        <div class="btnBox">
                            <el-button class="bgc-bv" @click="getData()">查询</el-button>
                        </div>
                    </div>
                </div>
                <!-- 照片列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                        ref="multipleTable"
                        :data="tableData"
                        :height="tableHeight"
                        size="small"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :header-cell-style="tableHeader"
                        stripe
                        >
                        <el-table-column
                            label="序号"
                            align="center"
                            type="index"
                            fixed
                            :index="indexMethod"
                        />
                        <el-table-column
                            label="姓名"
                            align="left"
                            show-overflow-tooltip
                            prop="userName"
                            width="80"
                        />
                        <el-table-column
                            label="身份证号"
                            align="left"
                            show-overflow-tooltip
                            prop="idcard"
                        />
                        <el-table-column
                            label="手机号"
                            align="left"
                            show-overflow-tooltip
                            prop="mobile"
                        />
                        <el-table-column
                            label="班级名称"
                            align="left"
                            show-overflow-tooltip
                            prop="projectName"
                            min-width="120"
                        />
                        <el-table-column
                            label="课程名称"
                            align="left"
                            show-overflow-tooltip
                            prop="courseName"
                        />
                        <el-table-column
                            label="职业/工种"
                            align="left"
                            show-overflow-tooltip
                            prop="categoryName"
                        />
                        <el-table-column
                            label="证件名称"
                            align="left"
                            show-overflow-tooltip
                            prop="certificatesName"
                            min-width="120"
                        />
                        <el-table-column
                            label="示例图片"
                            align="left"
                            prop="exampleList"
                            width="270px"
                        >
                        <template slot-scope="{ row }">
                            <el-image
                                v-for="item in row.exampleList"
                                :key="item"
                                style="width: 70px; height: 70px;margin-right: 10px;"
                                :src="item"
                                :preview-src-list="row.exampleList"
                                fit="cover"></el-image>
                        </template>
                        </el-table-column>
                        <el-table-column
                            label="证件照片"
                            align="left"
                            prop="serviceLogoUrl"
                        >
                        <template slot-scope="{ row }">
                            <el-image
                                v-if="row.photoUrl"
                                style="width: 70px; height: 70px"
                                :src="row.photoUrl"
                                :preview-src-list="[row.photoUrl]"
                                fit="cover"></el-image>
                        </template>
                        </el-table-column>
                        <el-table-column
                            label="创建时间"
                            align="left"
                            show-overflow-tooltip
                            prop="createTime"
                            width="150"
                        />
                        <el-table-column
                            label="是否上传证件照片"
                            align="left"
                            show-overflow-tooltip
                            prop="isUpload"
                        >
                        <template slot-scope="{row}">
                            {{row.isUpload?'是':'否'}}
                        </template>
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            align="center"
                            width="100px"
                            fixed="right"
                        >
                            <template slot-scope="scope">
                            <div>
                                <el-button
                                type="text"
                                size="mini"
                                style="padding: 0 5px"
                                @click="eliminate(scope.row.projectUserCertificatesId)"
                                >清空</el-button
                                >
                            </div>
                            </template>
                        </el-table-column>
                        <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="apiData"
                    @sizeChange="sizeChange"
                    @getData="getData"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Empty from "@/components/Empty.vue";
    import List from "@/mixins/List";
    import PageNum from "@/components/PageNum.vue";
    import moment from "moment";
    export default {
        name:"certificateInspection",
        components:{
            PageNum,
            Empty,
        },
        mixins: [List],
        doNotInit:true,
        created(){
           
        },
        data(){
            return{
                // 查询表单
                searchForm:{
                    userId:"",//学员id
                    projectId:"",//班级id
                    courseName:"",//课程名称
                    isUpload:true,//是否上传证件照片
                    
                },
                // 查询表单输入的内容
                searchInputForm:{
                    userName:"",//学员姓名
                    idcard:"",//身份证号
                    mobile:"",//手机号
                    projectName:"",//班级名称
                    projectCode:"",//班级编号
                    certificationTime:[],//创建时间
                },
                // 学员列表
                userList:[{}],
                // 班级列表
                projectList:[{}],
            }
        },
        methods:{
            getData(pageNum = 1) {
                const {searchForm} = this
                const params = {
                    pageNum: pageNum,
                    pageSize: this.pageSize,
                    isUpload:searchForm.isUpload
                };
                if(!searchForm.userId && !searchForm.projectId){
                    this.$message.warning("请选择学员信息或班级信息进行查询");
                    return;
                }
                if (searchForm.userId) {
                    params.userId = searchForm.userId
                }
                if (searchForm.projectId) {
                    params.projectId = searchForm.projectId
                }
                if (searchForm.courseName) {
                    params.courseName = searchForm.courseName
                }
                if (searchForm.startDate) {
                    params.startDate = searchForm.startDate
                }
                if (searchForm.endDate) {
                    params.endDate = searchForm.endDate
                }
                this.doFetch(
                    {
                        url: "/biz/projectUserCertificates/pageList",
                        params,
                        pageNum,
                    },
                );
            },
            // 清空
            eliminate(projectUserCertificatesId){
                this.$confirm(`是否清除证件照片?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    confirmButtonClass: "confirmButtonClass",
                    type: "warning",
                })
                .then(() => {
                this.$post("/biz/projectUserCertificates/clear", {projectUserCertificatesId})
                    .then((ret) => {
                        this.$message({
                            message: ret.message,
                            type: "success",
                        });
                        this.getData(-1);
                    })
                    .catch((err) => {
                        return;
                    });
                })
                .catch(() => {});
            },
            // 创建时间改变
            dataPickerChange(value){
                const {searchForm}=this;
                if(value){
                    searchForm.startDate=value[0];
                    searchForm.endDate=value[1];
                }else{
                    searchForm.startDate="";
                    searchForm.endDate="";
                }
            },
            // 搜索学员
            superUserSelect() {
                const {searchInputForm,searchForm}=this;
                this.$post("/biz/user/superUserSelect",{
                    pageNum: 1, //每次都只要第一页
                    pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
                    ...searchInputForm,
                    projectId:searchForm.projectId,
                },3000,false).then((res) => {
                    if (res.data.list.length === 0) {
                        this.userList = [{}];
                    } else {
                        this.userList = res.data.list;
                    }
                });
            },
            // 清除学员搜索信息
            clearUserSearchModel(e){
                const {searchInputForm}=this;
                if(e){
                    searchInputForm.userName="";
                    searchInputForm.idcard="";
                    searchInputForm.mobile="";
                    this.superUserSelect();
                }
            },
            // 搜索班级
            superProjectSelect() {
                const {searchInputForm,searchForm}=this;
                this.$post("/biz/project/superSelectProject",{
                    pageNum: 1, //每次都只要第一页
                    pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
                    projectName: searchInputForm.projectName,
                    projectCode: searchInputForm.projectCode,
                    userId: searchForm.userId,
                },3000,false).then((res) => {
                    if (res.data.list.length === 0) {
                        this.projectList = [{}];
                    } else {
                        this.projectList = res.data.list;
                    }
                });
            },
            // 清除班级搜索信息
            clearProjectSearchModel(e) {
                const {searchInputForm}=this;
                if(e){
                    searchInputForm.projectName="";
                    searchInputForm.projectCode="";
                    this.superProjectSelect();
                }
            },
        },
        watch:{
            
        }
    }
</script>

<style lang="less" scoped>
    .select-header {
        font-size: 14px;
        padding: 0 20px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #606266;
        height: 34px;
        line-height: 34px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
    }
    .framePage-body{
        .framePage-scroll{
            display: block !important;
            overflow: auto;
            
        }
    }
    // 对话框
    .imgDialog{
        .detail{
            display: flex;
            .left{
                width: 150px;
                // height: 150px;
                flex-shrink: 0;
                .el-image{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .right{
                margin-left: 20px;
                flex-grow: 1;
                .el-button{
                    float: right;
                }
            }
        }
        .originalImg{
            margin-top: 20px;
            width: 100%;
            .el-image{
                display: block;
                width: 100%;
            }
        }
    }
</style>